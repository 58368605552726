/* eslint-disable @nx/enforce-module-boundaries */
import {
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import {
  ArticleIconColored,
  CalendarNewIcon,
  ChevronDown,
  ChevronRight,
  ChevronUp,
  DiskNewIcon,
  JobsIconColored,
  MyNetworkNewIcon,
  PageIcon,
  HomeIcon,
  ProfileIcon,
  PageIconColored,
  MoreIcon,
  SettingsCardIcon,
  LinkIcons,
  ShareLink,
  Clone,
  Delete,
  TermsIcon,
  HelpSupportIcon,
  BusinessIcon,
  TermsIconColored,
  HelpSupportIconColored,
  BusinessIconColored,
  NotificationIconColored,
  MessageColoredIcon,
} from '@mybridge/icons';
import { MyEventsIcon, MyEventsIconColored } from '@mybridge/icons/MyEvents';
import { SettingsIcon, SettingsIconColored, TeamsIcon, TeamsIconColored } from '@mybridge/icons/user-menu';
import { Button, Card, Link, Badge, Circle } from '@mybridge/ui';
import { Heading } from '@mybridge/ui/heading';
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import { Text } from '@mybridge/ui/text';
import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../components/header/status-dropdown/statusProfile.module.scss';
import { getAllConnectionsData } from 'v4/store/actions/connection.actions';
import { useRouter } from 'next/router';
import {
  userStatus,
  userStatusMsg,
} from 'v4/components/header/status-dropdown/constant';
import classNames from 'classnames';

export const SiteShortcutLinks = ({ showCopyright, isDropDown, ...props }) => {
  const [showAll, setShowAll] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);
  const {push,pathname}=useRouter()
  const { allDegreeConnectionsArr } = useSelector((state) => state.connection);
  const { unreadMeetingCount } = useSelector((state) => state.globalData);
  const dispatch = useDispatch();
  const { onClose, onOpen, isOpen } = useDisclosure();

  
  // console.log("unreadMeetingCount = " + unreadMeetingCount);
  useEffect(() => {
    if (allDegreeConnectionsArr?.length === 0) {
      dispatch(getAllConnectionsData());
    }
  }, []);

  const connections = allDegreeConnectionsArr?.[0] ?? [];

  useEffect(() => {
    if (connections?.length) {
      if (connections?.length >= 3) {
        setShowMore(true);
        return;
      }
      setShowAll(true);
    }
  }, [connections]);

  const links = useMemo(
    () => [
      {
        title: 'Home',
        icon: <HomeIcon width={'24'} height={'24'} />,
        link: '/',
      },
      {
        title: 'myProfile',
        icon: (
          <ProfileIcon
            width={isDropDown ? '18' : '24'}
            height={isDropDown ? '18' : '24'}
          />
        ),
        link: '/profile/me',
      },
      {
        title: 'myNetwork',
        icon: (
          <MyNetworkNewIcon
            width={isDropDown ? '18' : '24'}
            height={isDropDown ? '18' : '24'}
          />
        ),
        link: '/profile/network',
      },
      {
        title: 'myCalendar',
        icon: (
          <CalendarNewIcon
            width={isDropDown ? '18' : '24'}
            height={isDropDown ? '18' : '24'}
          />
        ),
        link: '/profile/calendar',
      },
      {
        title: 'myMessages',
        icon: (
          <MessageColoredIcon
            width={isDropDown ? '18' : '24'}
            height={isDropDown ? '18' : '24'}
          />
        ),
        link: '/profile/',
      },
      {
        title: 'myNotifications',
        icon: (
          <NotificationIconColored
            width={isDropDown ? '18' : '24'}
            height={isDropDown ? '18' : '24'}
          />
        ),
        link: '/notification',
      },
      {
        title: 'myDisk',
        icon: (
          <DiskNewIcon
            width={isDropDown ? '18' : '24'}
            height={isDropDown ? '18' : '24'}
          />
        ),
        link: '/profile/disk',
      },
      {
        title: 'myPages',
        icon: (
          <PageIconColored
            width={isDropDown ? '18' : '24'}
            height={isDropDown ? '18' : '24'}
          />
        ),
        link: '/settings/pages',
      },
      {
        title: 'Jobs',
        icon: (
          <JobsIconColored
            width={isDropDown ? '18' : '24'}
            height={isDropDown ? '18' : '24'}
          />
        ),
        link: '/jobs',
      },
      {
        title: 'Teams',
        icon: (
          <TeamsIconColored
            width={isDropDown ? '18' : '24'}
            height={isDropDown ? '18' : '24'}
          />
        ),
        link: '/teams',
      },
      {
        title: 'Events',
        icon: (
          <MyEventsIconColored
            width={isDropDown ? '18' : '24'}
            height={isDropDown ? '18' : '24'}
          />
        ),
        link: '/myevents',
      },
      {
        title: 'Articles',
        icon: (
          <ArticleIconColored
            width={isDropDown ? '16' : '24'}
            height={isDropDown ? '16' : '24'}
          />
        ),
        link: '/articles',
      },
    ],
    []
  );
  console.log()
  return (
    <Stack {...props}>
      <Stack pos="relative" pb={showCopyright ? 4 : 0}>
        <Stack p={isDropDown ? 0 : 3} spacing={0}>
          {links
            ?.filter?.((_, ind) => isDropDown || ind < 8)
            ?.map?.((li, liIndex) => (
              <HStack
                as={Link}
                href={li.link}
                // bg='#e9e9e9'
                _hover={{
                  bg:"#b5b4b46e",
                  boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
                }}
                cursor="pointer"
                p={1}
                borderRadius={'50px'}
                key={liIndex}
                width={'fit-content'}
                pr={'35px'}
                m={1}
                onClick={() => setSelectedLink(li.title)}
                >
                <Box
                bg={pathname === li.link ? '#D9D9D9' : ''}
                  w={isDropDown ? '30px' : '40px'}
                  h={'40px'}
                  borderRadius="50%"
                  // bg="#D9D9D9"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {li.icon}
                </Box>
                <Text fontSize={'17px'} color={ pathname === li.link ?"black":"#575757"} fontWeight={"600"} flex={1}>
                  {li.title}
                </Text>
                {li.title == 'myCalendar' && unreadMeetingCount > 0 && (
                  <Circle
                    bg="brandRed.500"
                    color="white"
                    w="20px"
                    h="20px"
                    p="8px"
                    borderRadius="100%"
                  >
                    {unreadMeetingCount}
                  </Circle>
                )}
                {/* <ChevronRight height="12" /> */}
              </HStack>
            ))}

          <Box pr={'40px'}>
            <Popover isOpen={isOpen} onClose={onClose} placement="top-start" isLazy>
              <PopoverTrigger>
                <Box onClick={onOpen} _hover={{ bg: 'gray.100' }} className={styles.moreOption}>
                  <Box
                    display={['block', 'block', 'block']}
                    width={['18px', '18px', 'auto']}
                    mr={2}
                  >
                    <MoreIcon
                      width={ '24'}
                      height={'24'}
                    />
                  </Box>
                  <Box flex="1" fontWeight={"600"} display={['block', 'block', 'block']}>
                    {'More'}
                  </Box>
                  <Box display={['block', 'block', 'block']}>
                  </Box>
                </Box>
              </PopoverTrigger>
              <PopoverContent marginBottom="-50px" className={styles.moreDropdown}>
                <PopoverBody style={{ outline: 'none', borderRadius: '8px' }}>
                  <List>
                    {[
                      { title: 'myJobs', icon: <JobsIconColored width="24" height="24" />,link: '/jobs', },
                      { title: 'myTeams', icon: <TeamsIconColored width="24" height="24" />,link: '/teams', },
                      { title: 'myEvents', icon: <MyEventsIconColored width="24" height="24" />,link: '/myevents', },
                      { title: 'myArticles', icon: <ArticleIconColored width="24" height="24" />,link: '/articles', },
                      { title: 'mySettings', icon: <SettingsIconColored width="24" height="24" />,link: '/settings/account', },
                      { title: 'Terms & Policy', icon: <TermsIconColored width="24" height="24" />,link: '/terms-of-use', },
                      { title: 'Support', icon: <HelpSupportIconColored width="24" height="24" />,link: '/support', },
                      { title: 'Business', icon: <BusinessIconColored width="24" height="24" />,link: '/business', },
                    ].map((item, index) => (
                      <ListItem
                  zIndex={999999999999999}
                        key={index}
                        cursor="pointer"
                        className={styles.dropItem}
                        // key={i}
                        as={HStack}
                        alignItems="flex-start"
                        _hover={{ bg: 'gray.100' }}
                        borderRadius="5"
                        
                        onClick={() => {
                          // Call onClose to close the popover when item is clicked
                          onClose();
                          // Redirect or perform other actions
                          // push(item.link);
                        }}
                      >
                        <HStack flex={1} alignItems="center">
                          <Box as="span" className={styles.shortcutsIcon}>
                            {item.icon}
                          </Box>
                          <Box>
                            <Text>{item.title}</Text>
                          </Box>
                        </HStack>
                        {/* <Box>{selected.id === status.id ? <CheckIcon /> : ''}</Box> */}
                      </ListItem>
                    ))}
                  </List>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>

          {/* 
          <HStack
           
            _hover={{
              bg: '#b5b4b46e',
              boxShadow: '0px 14px 44px 0px rgba(0, 0, 0, 0.04)',
            }}
            cursor="pointer"
            p={1}
            borderRadius={'50px'}
        
            width={'fit-content'}
            pr={'35px'}
            m={1}
          >
            <Box
              w={'50px'}
              h={'40px'}
              borderRadius="full"
            
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <MoreIcon width={'38'} height={'38'} />
            </Box>
            <Text fontSize={'17px'} flex={1}>
              More
            </Text>

         
          </HStack> */}
        </Stack>
        {/* {console.log(isDropDown, showMore, 'mmmmmmmoee')} */}
        {/* {!isDropDown && showMore && (
          <Button
            rightIcon={
              showAll ? (
                <ChevronUp color={'#3D5A80'} />
              ) : (
                <ChevronDown color={'#3D5A80'} />
              )
            }
            onClick={(e) => {
              setShowAll(!showAll);
              sessionStorage.setItem('showMore', showAll ? 'true' : '');
            }}
            variant="transparent"
          >
            <Text color="brandPrimary.500">
              See {showAll ? 'Less' : 'More'}
            </Text>
          </Button>
        )} */}
        {/* {showCopyright ? <MainCopyrightsText /> : <></>} */}
        <div></div>
      </Stack>
    </Stack>
  );
};
