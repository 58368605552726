/* eslint-disable @nx/enforce-module-boundaries */
import { NotificationIcon, NotificationIconColored } from '@mybridge/icons';
import {
  DropNotifIcon,
  MenuThreeDots,
  SimpleTickIcon,
} from '@mybridge/icons/NotificationIcons';
import {
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  PopoverFooter,
  Button,
  Heading,
  VStack,
  useDisclosure,
  Badge,
  Portal,
  Tooltip,
} from '@mybridge/ui';
import { Text } from '@mybridge/ui/text';
import React, { useEffect, useState } from 'react';
import { ThreeDotsIcon, EmptyNotifIcon } from '@mybridge/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNotificationList,
  setGlobalNotificationCount,
  markAllRead,
} from 'v4/store/actions/notification.actions';
import { isBeforeToday, isToday } from 'v4/utils/utils';
import moment from 'moment';
import NotificationCard from './notification-card';
import Link from 'next/link';
import { setMeetingWindowed } from 'v4/store/actions/meeting.actions';
import { Menu, MenuButton, MenuList, MenuItem } from '@mybridge/ui/menu';
import { useRouter } from 'next/router';
import { reverse } from 'lodash';
import { isMobile } from 'react-device-detect';

const NotificationDropdown = (props) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { currentMeetingState } = useSelector((state) => state.meeting);
  const { notificationsList, loading, updatedStatus, globalNotifCount } =
    useSelector((state) => state.notifications);
  const notifDisc = useDisclosure();
  const todayDate = moment().format('YYYY-MM-DD');
  const [todayNotifs, setTodayNotifs] = useState([]);
  const [earlierNotifs, setEarlierNotifs] = useState([]);
  const [unreadMessage, setUnreadMessage] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [unreadListActive, setUnreadListActive] = useState(false);

  const isToday = (meetingDate) => {
    return moment(meetingDate).format('YYYY-MM-DD') === todayDate;
  };

  const isBeforeToday = (meetingDate) => {
    return moment(meetingDate).isBefore(todayDate);
  };

  const handleReadAllMessage = () => {
    dispatch(
      markAllRead({
        status: 2, // 1 for unread/2 for read/ 2 for archive
      })
    );
  };

  useEffect(() => {
    if (!notificationsList) {
      dispatch(getNotificationList());
    }
  }, []);

  useEffect(() => {
    if (globalNotifCount) {
      // dispatch(setGlobalNotificationCount(unreadMessage.length));
      setUnreadCount(globalNotifCount);
    }
  }, [globalNotifCount]);

  useEffect(() => {
    if (updatedStatus) {
      dispatch(getNotificationList());
    }
  }, [updatedStatus]);

  useEffect(() => {
    if (currentMeetingState) {
      dispatch(setMeetingWindowed());
    }
    if (globalNotifCount > 0) {
      dispatch(setGlobalNotificationCount()).then((res) => {
        if (res) {
          setUnreadCount(0);
        }
      });
    }
  }, [notifDisc.isOpen]);

  useEffect(() => {
    if (notificationsList?.length) {
      setTodayNotifs([]);
      setEarlierNotifs([]);
      setUnreadMessage([]);
      notificationsList?.slice(0, 8)?.forEach((message) => {
        if (isToday(message.created_at))
          setTodayNotifs((prevState) => [message, ...prevState]);
        else if (isBeforeToday(message.created_at))
          setEarlierNotifs((prevState) => [message, ...prevState]);
        if (message.status === 1)
          setUnreadMessage((prevState) => [message, ...prevState]);
      });
    }
  }, [notificationsList]);

  const todaysSortArray = todayNotifs
    ?.map((item) => ({ ...item, momentDate: moment(item.created_at) }))
    .sort((a, b) => a.momentDate - b.momentDate);
  const earlierSortArray = earlierNotifs
    ?.map((item) => ({ ...item, momentDate: moment(item.created_at) }))
    .sort((a, b) => a.momentDate - b.momentDate);
  const unreadSortArray = unreadMessage
    ?.map((item) => ({ ...item, momentDate: moment(item.created_at) }))
    .sort((a, b) => a.momentDate - b.momentDate);

  return (
    <Popover {...notifDisc}>
      <PopoverTrigger onClick={() => notifDisc.onOpen()}>
        <IconButton
          bg="transparent"
          position="relative"
          // px={[1, 2, 8]}
          size={isMobile ? 'sm' : 'md'}
          variant="homeHeaderButtons"
          isActive={router?.pathname === '/notification' ? true : false}
          fill={"red"}
        >
          <>
            {unreadCount > 0 ? (
              <Badge
                // fontSize="8px"
                position="absolute"
                bg="brandRed.500"
                color="white"
                borderRadius="100%"
                top="0"
                minW="25px"
                border= '2px solid #ffffff'
                p="3px 5px"
                left="50%"
              >
                {unreadCount}
              </Badge>
            ) : (
              ''
            )}
            <Tooltip label="Notifications" bg="black" p={2}>
              <Box as="span">
                <NotificationIconColored
                  width={isMobile && !props.width? '26' : props.width}
                  height={isMobile && !props.height ? '26' : props.height}
                  // fill="red"
                />
              </Box>
            </Tooltip>
          </>
        </IconButton>
      </PopoverTrigger>
      <Portal>
        <PopoverContent width={['100vw', '400px', '400px']}>
          <PopoverHeader border="0px">
            <HStack justifyContent="space-between">
              <Text fontSize="md" fontWeight="bold">
                Notifications
              </Text>
              <Menu>
                <MenuButton
                  as={IconButton}
                  variant="transparent"
                  p={0}
                  w="12px"
                  h="10px"
                  justifyContent="center"
                  icon={<MenuThreeDots width={11} height={4} />}
                />
                <MenuList>
                  <MenuItem
                    onClick={() => handleReadAllMessage()}
                    variant="transparent"
                  >
                    <SimpleTickIcon /> <Text ms={3}>Mark all as read</Text>
                  </MenuItem>
                  <MenuItem
                    onClick={() => router.push('/notification')}
                    variant="transparent"
                  >
                    <DropNotifIcon />
                    <Text ms={3}>Open Notifications</Text>
                  </MenuItem>
                  <MenuItem
                    onClick={() => router.push('/settings/notifications')}
                    variant="transparent"
                  >
                    <DropNotifIcon />
                    <Text ms={3}>Notification Settings</Text>
                  </MenuItem>
                </MenuList>
              </Menu>
              {/* <ThreeDotsIcon /> */}
            </HStack>
          </PopoverHeader>
          <PopoverBody py="15px">
            <Tabs
              variant="soft-rounded"
              colorScheme="gray"
              maxH="400px"
              overflow="auto"
            >
              <TabList>
                <Tab py="2px">All</Tab>
                <Tab py="2px">Unread</Tab>
              </TabList>
              <TabPanels>
                <TabPanel px="0">
                  {!loading && notificationsList && todayNotifs.length ? (
                    <>
                      <Text fontWeight="bold">New</Text>
                      {todaysSortArray?.reverse().map((message, i) => (
                        <NotificationCard
                          key={i}
                          details={message}
                          onClose={() => notifDisc.onClose()}
                        />
                      ))}
                    </>
                  ) : (
                    ''
                  )}
                  {!loading && notificationsList && earlierNotifs.length ? (
                    <>
                      <Text fontWeight="bold">Earlier</Text>
                      {earlierSortArray?.reverse().map((message, i) => (
                        <NotificationCard
                          key={i}
                          details={message}
                          onClose={() => notifDisc.onClose()}
                        />
                      ))}
                    </>
                  ) : (
                    !todayNotifs.length && (
                      <Box align="center" py="25px" w="100%">
                        <EmptyNotifIcon />
                      </Box>
                    )
                  )}
                </TabPanel>
                <TabPanel px="0">
                  {!loading && notificationsList && unreadMessage.length ? (
                    <>
                      <Text fontWeight="bold">New</Text>
                      {unreadSortArray?.reverse().map((message, i) => (
                        <NotificationCard
                          key={i}
                          details={message}
                          onClose={() => notifDisc.onClose()}
                        />
                      ))}
                    </>
                  ) : (
                    <Box align="center" py="25px" w="100%">
                      <EmptyNotifIcon />
                    </Box>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </PopoverBody>
          <PopoverFooter align="center" justifyContent="center" display="flex">
            <Link href="/notification" variant="transparent">
              <Button variant="transparent" onClick={() => notifDisc.onClose()}>
                All Notifications
              </Button>
            </Link>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default NotificationDropdown;
