/* eslint-disable @nx/enforce-module-boundaries */
'use client';
import { MyBridgeLogoMain } from '@mybridge/icons/MyBridgeLogo';
import { SearchIcon } from '@mybridge/icons/Search';
import {
  Avatar,
  Button,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Progress,
  Spinner,
  Text,
} from '@mybridge/ui';
import { Input, InputGroup, InputLeftElement } from '@mybridge/ui/input';
import {
  Box,
  Circle,
  HStack,
  Spacer,
  Stack,
  VStack,
} from '@mybridge/ui/layout';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { SearchContext } from 'v4/snippets/search/context';
import { AuthHeader } from '../auth/auth-header';
import { PrependPageHeadTitle } from '../custom-head';
import { useDispatch } from 'react-redux';
import styles from './index.module.scss';
import {
  CalendarIcon,
  CalendarNewIcon,
  DashboardLayoutIcon,
  DiskIcon,
  DiskNewIcon,
  EventClockIcon,
  FolderIcon,
  GroupIcon,
  HomeIcon,
  LayoutModeIcon,
  MyBridgeCircleIcon,
  SimpleCloseIcon,
  UsersIconColored,
} from '@mybridge/icons';
import { isMobile, isTablet } from 'react-device-detect';
import { useDisclosure } from '@mybridge/ui';
import { ProfileMenu } from '../auth/auth-header/profile-menu';
import MobileDrawerMenu from '../auth/mobile-drawer-menu';
import { logout } from 'v4/store/actions/auth.actions';
import {
  getRecentSearch,
  deleteRecentSearchID,
} from 'v4/store/actions/global.data.actions';
import RecentSearchListBox from 'v4/components/header/recent-search-box';
import { JobsListingSearchBar } from '../jobs/search-bar';
import { JobsListingContext } from '../jobs/context';
import NotificationDropdown from '../auth/auth-header/notifications';
import ShareLinkModalPublic from '../../pages/appointment/[slug]/share-link-modal'
import { checkAppointmentSlug } from 'v4/store/actions/calendar.actions';
import { getUserFullName } from 'v4/lib/commons';
const Header = (props) => {
  const { loggedIn, pageLoaded } = useSelector((state) => state.user);
  const { globalNotifCount } = useSelector((state) => state.notifications);
  const { loading } = useSelector((state) => state.userProfile);
  const { recentSearches, loading: searchLoading } = useSelector(
    (state) => state.globalData
  );
  const { isPublicAppointment } = useSelector((state) => state.calendar);
  const { search, setSearch, setActiveTab } = useContext(SearchContext);
  const [openShareModal,setOpenShareModal]=useState(false)
  const [getAccountInfo, setGetAccountInfo] = useState([]);
  const dispatch = useDispatch();
  const { push, pathname, query } = useRouter();
  const isDiskPage = pathname?.includes('profile/disk');
  const sideDrawerDisc = useDisclosure();
  const searchBoxDisc = useDisclosure();
  const inputRef = useRef(null);
  const [newSearch, setNewSearch] = useState([]);
  const isJobsPage = pathname?.includes('jobs');
  const isAppointmentPublicPage = pathname?.includes('appointment');
  const [userInfo, setUserInfo] = useState([]);

  console.log(pathname, 'pathNamezz');
  useEffect(() => {
    let array = recentSearches?.results;
    let temp = [];
    let newArray = array?.filter((ele) => {
      if (!temp.includes(ele.query) && temp.length < 5) {
        temp.push(ele.query);
        return true;
      } else {
        return false;
      }
    });
    setNewSearch(newArray);
  }, [recentSearches]);

  const handleSearch = (e) => {
    if (e.keyCode === 13) {
      const val = e?.target?.value;
      setSearch(val);
      if (val.length > 2) {
        setActiveTab?.(val?.length ? 'all' : 'posts');
        if (val?.length) {
          push('/g/?query=' + e?.target?.value);
        } else {
          push('/');
        }
      }
    }
  };

  const handleRecentSearch = (e) => {
    const val = e;
    setSearch(val);
    setActiveTab?.(val?.length ? 'all' : 'posts');
    if (val?.length) {
      push('/g/?query=' + e);
    } else {
      push('/');
    }
  };

  useEffect(() => {
    // Event listener for window focus
    const handleWindowFocus = () => {
      // Check if access token exists in localStorage
      const accessToken = localStorage.getItem('access');

      if (accessToken) {
        // Parse the token to get the expiration time
        const { exp } = JSON.parse(atob(accessToken.split('.')[1]));

        // Check if the token is expired
        const isTokenExpired = Date.now() >= exp * 1000;
        if (isTokenExpired) {
          console.log('Access token is expired. Redirect to login page.');
          dispatch(logout());
          push('/');
        } else {
          console.log('Access token is still valid.');
        }
        // } else {
        //   dispatch(logout());
        //   push('/');
      }
    };

    // Attach the event listener to the window focus event
    window.addEventListener('focus', handleWindowFocus);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('focus', handleWindowFocus);
    };
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    if (recentSearches?.count === 0 && loggedIn) dispatch(getRecentSearch());
  }, [loggedIn]);

  const handleRecentDelete = () => {
    const payload = { id: 'all' };
    dispatch(deleteRecentSearchID(payload)).then((res) => {
      if (res) dispatch(getRecentSearch());
    });
  };

  const handleCloseShareLinkModal=()=>{
    setOpenShareModal(false)
  }


  useEffect(()=>{
    if(!isAppointmentPublicPage||!query?.slug)return

    dispatch(checkAppointmentSlug({ search: query.slug })).then((res) => {
      console.log(res, 'ress');

      // if (res.payload.results.length > 0) {
        setGetAccountInfo(res.payload.results?.[0]);
      // }
    });
  },[query?.slug])

  return (<>
  {(
  <>
  <PrependPageHeadTitle>
    {loggedIn && globalNotifCount ? `(${globalNotifCount})` : ''}
  </PrependPageHeadTitle>

  {!!isAppointmentPublicPage && !!isPublicAppointment && (
    <header style={{ zIndex: 999  ,width: isMobile ? "108%" : "100%"}} className={styles.header}>
      <Stack
        pos="relative"
        w="100%"
        bg="white"
        boxShadow="0px 2px 10px 0px #00000033"
        borderBottom={'1px solid #ddd'}
        // zIndex={9999999}
      >
        <HStack py={4} px={36}>
          {!!getAccountInfo?.first_name && (
            <HStack
              cursor="pointer"
              onClick={() => {
                push(`/p/${getAccountInfo.slug}`);
              }}
            >
              {/* <Avatar color="white" name={getAccountInfo?.first_name+" "+getAccountInfo?.last_name ?? " "} size="sm" src={''} />
        <Text>{getAccountInfo?.first_name+" "+getAccountInfo?.last_name ?? " "}</Text> */}
              <HStack p={3} _hover={{ bg: '#F2F2F2' }} cursor="pointer">
                <Avatar
                  src={getAccountInfo?.profile_pic}
                  name={`${getAccountInfo?.first_name} ${getAccountInfo?.last_name}`}
                  size="md"
                />
                <Text
                  fontSize="20px"
                  fontWeight="600"
                  color="brandPrimary.500"
                >
                  {getUserFullName(getAccountInfo)}{' '}
                  {getAccountInfo?.pronouns
                    ? `(${getAccountInfo?.pronouns})`
                    : `(She/Her)`}
                </Text>
              </HStack>
            </HStack>
          )}

          <Spacer />
          {!!getAccountInfo?.appointment_profile && (
            <Button
              onClick={() => {
                setOpenShareModal(true);
              }}
              fontSize="15px"
              bg="#3D5A80"
              color="#FFFFFF"
              variant="solid"
            >
              Share
            </Button>
          )}
        </HStack>
      </Stack>
    </header>
  )}
  {!isAppointmentPublicPage && (
    <header className={styles.header} style={{ zIndex: 999  ,width: isMobile ? "108%" : "100%"}}>
      <Stack
        pos="relative"
        w="100%"
        bg="white"
        // shadow={[0, 'md']}
        borderBottom={['1px solid #ddd', 0]}
      >
        <HStack
          w={!isMobile ? ['1320px'] : ''}
          // maxW="1366px"
          p={!isMobile ? ['5px 10px 0', 2] : ''}
          justifyContent="space-around"
          margin={!isMobile ? 'auto' : ''}
        >
          <HStack
            pl={loggedIn ? ['0px', '15px'] : '0px'}
            mr={3}
            w={[
              'auto',
              'auto',
              loggedIn ? (isJobsPage ? 'auto' : '20%') : '650px',
            ]}
          >
            <Box>
              {isMobile || isTablet ? (
                <MyBridgeCircleIcon
                  width="44px"
                  onClick={() => sideDrawerDisc.onOpen()}
                />
              ) : (
                <Link href="/">
                  {/* <MyBridgeLogoMain width={isMobile ? '30px' : '44px'} /> */}

                  <MyBridgeCircleIcon
                    width={isMobile ? '30px' : '49px'}
                    // onClick={() => sideDrawerDisc.onOpen()}
                  />
                </Link>
              )}
            </Box>
          </HStack>
          <HStack
            pl={[0, 0, '15px']}
            w={!isMobile ? '720px' : !loggedIn?"fit-content": '110px'}
            flex={'none'}
          >
            <Box w={!isMobile&&!loggedIn ? '580px':isMobile&&!loggedIn ? "280px": '360px'}>
              {isJobsPage && !isMobile ? (
                <JobsListingSearchBar />
              ) : recentSearches &&
                recentSearches?.count > 0 &&
                loggedIn ? (
                <>
                  {(!isMobile && !isTablet) || searchBoxDisc?.isOpen ? (
                    <Popover>
                      <PopoverTrigger>
                        <InputGroup>
                          <InputLeftElement
                            width={['30px', '48px']}
                            height={['30px', '48px']}
                          >
                            <SearchIcon
                              width={isMobile ? '14px' : '18px'}
                            />
                          </InputLeftElement>
                          <Input
                            pl={10}
                            bg="brandGray.500"
                            border="none"
                            borderRadius="full"
                            defaultValue={search}
                            // boxShadow="0 4px 11px 0 rgb(0 0 0 / 28%)"
                            placeholder={
                              loggedIn
                                ? 'Search...'
                                : 'Search for People, Jobs, Companies, Posts, Events, Teams, Articles...'
                            }
                            onKeyDown={handleSearch}
                            height={['32px', '48px']}
                          />
                        </InputGroup>
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent w={!isMobile&&!loggedIn ? '580px':isMobile&&!loggedIn ? "280px": '360px'} className={styles.searchPopUp}>
                          <PopoverBody
                            style={{
                              outline: 'none',
                              borderRadius: '80px',
                              padding: '0',
                            }}
                          >
                            <InputGroup>
                              <InputLeftElement
                                width={['30px', '48px']}
                                height={['30px', '48px']}
                              >
                                <SearchIcon
                                  width={isMobile ? '14px' : '18px'}
                                />
                              </InputLeftElement>
                              <Input
                                pl={10}
                                bg="#FFFFFF"
                                border="none"
                                borderRadius="full"
                                ref={inputRef}
                                defaultValue={search}
                                // boxShadow="0 4px 11px 0 rgb(0 0 0 / 28%)"
                                placeholder={
                                  loggedIn
                                    ? 'Search...'
                                    : 'Search for People, Jobs, Companies, Posts, Events, Teams, Articles...'
                                }
                                onKeyDown={handleSearch}
                                height={['32px', '48px']}
                              />
                            </InputGroup>
                            <Box className={styles.searchPopUpBottomLine} />
                            <VStack
                              align="stretch"
                              className={styles.searchGroup}
                            >
                              <HStack justifyContent="space-between">
                                <Text
                                  fontSize="14px"
                                  color="black"
                                  fontWeight="600"
                                >
                                  Recent
                                </Text>
                                <Button
                                  variant="transparent"
                                  size="md"
                                  p={0}
                                  style={{
                                    fontWeight: '500',
                                    color: '#3D5A80',
                                  }}
                                  onClick={() => handleRecentDelete()}
                                >
                                  Clear Searches
                                </Button>
                              </HStack>
                              {!searchLoading ? (
                                recentSearches &&
                                newSearch?.map((recent, i) => (
                                  <RecentSearchListBox
                                    key={i}
                                    recent={recent}
                                    onClick={() =>
                                      handleRecentSearch(recent?.query)
                                    }
                                    cursor="pointer"
                                  />
                                ))
                              ) : (
                                <HStack justifyContent="center">
                                  <Spinner />
                                </HStack>
                              )}
                            </VStack>
                          </PopoverBody>
                        </PopoverContent>
                      </Portal>
                    </Popover>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <InputGroup>
                    <InputLeftElement
                      width={['30px', '48px']}
                      height={['30px', '48px']}
                    >
                      <SearchIcon width={isMobile ? '14px' : '18px'} />
                    </InputLeftElement>
                    <Input
                      pl={10}
                      bg="brandGray.50"
                      border="none"
                      ref={inputRef}
                      borderRadius="full"
                      defaultValue={search}
                      placeholder={
                        loggedIn
                          ? 'Search...'
                          : 'Search for People, Jobs, Companies, Posts, Events, Teams, Articles...'
                      }
                      onKeyDown={handleSearch}
                      height={['32px', '48px']}
                    />
                  </InputGroup>
                </>
              )}
            </Box>
          </HStack>

          <HStack
            pl={loggedIn ? ['0px', '0px'] : '0px'}
            w={[
              'auto',
              'auto',
              loggedIn ? (isJobsPage ? 'auto' : '315px') : '650px',
            ]}
            display="flex"
            justifyContent="end"
          >
            <AuthHeader
              onSearchClose={() => searchBoxDisc?.onClose()}
              onSearchOpen={() => searchBoxDisc?.onToggle()}
            />
          </HStack>
        </HStack>
        {loading || !pageLoaded ? (
          <Progress
            top={0}
            left={0}
            right={0}
            position="absolute"
            isIndeterminate
            h={1}
          />
        ) : (
          ''
        )}
      </Stack>
      {(isMobile || isTablet) && loggedIn ? (
            <HStack
              bg={isDiskPage? "#f9f9f9": "white"}
              borderBottom="1px solid #ddd"
              justifyContent="space-between"
              p="2% 0%"
            >
              <IconButton
                icon={<HomeIcon width="26" height="26" />}
                variant="transparent"
                onClick={() => push('/')}
              />
              <IconButton
                icon={<GroupIcon width="26" height="26" />}
                variant="transparent"
                onClick={() => push('/profile/network')}
              />
              <IconButton
                icon={<CalendarIcon width="24" height="24" />}
                variant="transparent"
                // onClick={() => calendarDisc.onOpen()}
                onClick={() => push('/profile/calendar')}
              />
              <IconButton
                icon={<FolderIcon width="26" height="26" color="#5B5B5B" />}
                variant="transparent"
                onClick={() => push('/profile/disk')}
              />
              {isMobile || isTablet ? <NotificationDropdown /> : <></>}

              <ProfileMenu>
                <IconButton
                  icon={<DashboardLayoutIcon width="26" height="26" />}
                  variant="transparent"
                />
              </ProfileMenu>
            </HStack>
      ) : (
        <></>
      )}

      <MobileDrawerMenu
        onOpen={sideDrawerDisc?.isOpen}
        onClose={() => sideDrawerDisc?.onClose()}
      />
    </header>
  )}
  <ShareLinkModalPublic
    isOpen={openShareModal}
    onClose={handleCloseShareLinkModal}
  />
</>

  )}
  



    </>
  );
};

Header.propTypes = {};

export default Header;
