/* eslint-disable @nx/enforce-module-boundaries */
import React, { useContext, useEffect, useState } from 'react';
import { IconButton } from '@mybridge/ui/icon-button';
import { ChatIcon, NewGroup, ChatDefaultIcon } from '@mybridge/icons';
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from '@mybridge/ui/popover';
import { HStack, Box, Circle } from '@mybridge/ui/layout';
import { Text } from '@mybridge/ui/text';
import ConversationListCard from 'v4/snippets/chat/conversation-card';
import { Button } from '@mybridge/ui/button';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useDisclosure } from '@chakra-ui/hooks';
import { setMeetingWindowed } from 'v4/store/actions/meeting.actions';
import { Badge, Portal } from '@mybridge/ui';
import Link from 'next/link';
import SectionLoader from 'v4/components/common/loader/sectionLoader';
import { isMobile } from 'react-device-detect';
import { Tooltip } from '@mybridge/ui';
import { setMainChatNotification } from 'v4/store/actions/chat.actions';
const ConversationList = (props) => {
  const dispatch = useDispatch();
  const { currentMeetingState } = useSelector((state) => state.meeting);
  const {
    matrixClient,
    setupMatrixClient,
    teamsChats,
    newChatDisc,
    unreadCount,
    setUnreadCount,
  } = useContext(MatrixContext);
  const chatMenuDisc = useDisclosure();
  const [chatList, setChatList] = useState([]);

  const [showCount, setShowCount] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (matrixClient) {
      const roomList = matrixClient.getRooms();

      // Sort the rooms based on the most recent activity
      const sortedRooms = roomList.sort((a, b) => {
        const lastEventA = a.timeline[a.timeline.length - 1];
        const lastEventB = b.timeline[b.timeline.length - 1];
        // console.log('Room Names', a.name);
        if (lastEventA && lastEventB) {
          return lastEventB.getTs() - lastEventA.getTs();
        }
      });

      // Filter rooms based on conditions (more than one member and contains messages)
      const filteredRooms = sortedRooms.filter((room) => {
        // Check if the room has more than one member
        const members = room.getJoinedMembers();
        const timelineEvents = room.getLiveTimeline().getEvents();
        const lastMessageEvent = timelineEvents.find((event) => {
          return event.getType() === 'm.room.message';
        });
        // console.log('lastMessageEvent', lastMessageEvent);
        // Check if the room has messages
        return members?.length > 1 && lastMessageEvent;
      });
      setChatList(filteredRooms);

      const totalUnreadCounts = filteredRooms.reduce((count, room) => {
        return count + room.getUnreadNotificationCount('total');
      }, 0);
      setUnreadCount(totalUnreadCounts);
      dispatch(setMainChatNotification(totalUnreadCounts));
      // setChatList(_.toArray(conversationList));
      setLoading(false);
    }
  }, [matrixClient, chatMenuDisc.isOpen]);

  useEffect(() => {
    if (matrixClient === null) {
      setupMatrixClient();
    }
  }, []);

  useEffect(() => {
    if (currentMeetingState) {
      dispatch(setMeetingWindowed());
    }
  }, [chatMenuDisc.isOpen]);

  useEffect(() => {
    if (unreadCount > 0) {
      setShowCount(true);
    }
  }, [unreadCount]);

  return (
    <>
    <Box>
      <Popover placement="bottom-end" {...chatMenuDisc} >
        <PopoverTrigger onClick={() => chatMenuDisc.onOpen()}>
          {!isMobile ? <IconButton
            bg="transparent"
            _hover={{
              backgroundColor: props?.isAuth !=="Auth" && "transparent",
            }}        
            position="relative"
            // px={[1, 2, 8]}
            size={isMobile ? 'sm' : 'md'}
            variant="homeHeaderButtons"
          >
            <>
              {unreadCount > 0 ? (
                <Badge
                  position="absolute"
                  bg="brandRed.500"
                  color="white"
                  borderRadius="100%"
                  top="0"
                  minW="25px"
                  border="2px solid #ffffff"
                  p="3px 5px"
                  left="50%"
                  zIndex="3"
                >
                  {unreadCount}
                </Badge>
              ) : (
                ''
              )}
              <Tooltip label="Messenger" bg="black" p={2}>
                <Box as="span"   _hover={{
              backgroundColor:"transparent"
               }}>
                  <ChatIcon
                    width={isMobile && !props.width? '18' : props.width}
                    height={isMobile && !props.height ? '18' : props.height}
                  />
                </Box>
              </Tooltip>
            </>
          </IconButton> : <IconButton
            position="relative"
            minW="32px"
            borderRadius="full"
            w="32px"
            h="32px"
            BoxShadow="0px 0px 10px 0px #00000029;0px 4px 5px 0px #0000000F inset;0px -5px 3px 0px #0000000F inset;"
            bg="#DEDEDE"
          >
            <>
              {unreadCount > 0 ? (
                <Badge
                  position="absolute"
                  bg="brandRed.500"
                  color="white"
                  borderRadius="100%"
                  top="0"
                  minW="25px"
                  border="2px solid #ffffff"
                  p="3px 5px"
                  left="50%"
                  zIndex="3"
                >
                  {unreadCount}
                </Badge>
              ) : (
                ''
              )}
              <Tooltip label="Messenger" bg="black" p={2}>
                <Box as="span">
                  <ChatIcon
                    width={isMobile ? '18' : '35'}
                    height={isMobile ? '18' : '35'}
                  />
                </Box>
              </Tooltip>
            </>
          </IconButton>}
          
        </PopoverTrigger>
        <Portal  zIndex={"9999999999"}>
          <PopoverContent zIndex={"9999999999"} width={['100vw', '300px', '300px']} >
            <PopoverHeader border="0px">
              <HStack justifyContent="space-between">
                <Text fontSize="md" fontWeight="bold">
                  Chat
                </Text>{' '}
                <NewGroup
                  width={30}
                  height={30}
                  cursor="pointer"
                  onClick={() => {
                    newChatDisc.onOpen();
                    chatMenuDisc.onClose();
                  }}
                />
              </HStack>
            </PopoverHeader>
            <PopoverBody maxH="400px" overflow="auto">
              {!loading ? (
                chatList.length > 0 ? (
                  chatList.map((list, i) => {
                    if (!teamsChats.includes(list.roomId)) {
                      return (
                        <ConversationListCard
                          key={i}
                          chatDetails={list}
                          onClose={() => chatMenuDisc.onClose()}
                          menuState={chatMenuDisc.isOpen}
                        />
                      );
                    }
                  })
                ) : (
                  <HStack justifyContent="center" py="50px">
                    <ChatDefaultIcon />
                  </HStack>
                )
              ) : (
                <SectionLoader />
              )}
            </PopoverBody>
            <PopoverFooter align="center" justifyContent="center">
              <Link href={'/profile/network'}>
                <Button variant="transparent">All Network</Button>
              </Link>
            </PopoverFooter>
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
    </>
  );
};

export default ConversationList;
