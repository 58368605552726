export const statusColor = {
  available: '#0AB33E',
  busy: '#FF0600',
  away: '#5B5B5B',
}

export const userStatus = [
  {
    id: 1,
    title: 'Available',
    subTitle: 'Based on activity',
    color: statusColor.available,
  },
  {
    id: 2,
    title: 'Busy',
    subTitle: 'Mute chat notifications',
    color: statusColor.busy,
  },
  {
    id: 3,
    title: 'Away',
    color: statusColor.away,
  },
];

export const userStatusMsg = [
  {
    id:1,
    title: 'In a meeting',
    key:'in_a_meeting',
    icon: '/images/meetingStatusIcon.svg',
    presence:'busy'
  },
  {
    id:2,
    title: 'Traveling',
    key:'traveling',
    icon: '/images/travellingStatusIcon.svg',
    presence:'offline'
  },
  {
    id:3,
    title: 'Out of office',
    key:'out_of_office',
    icon: '/images/oofficeStatusIcon.svg',
    presence:'offline'
  },
  {
    id:4,
    title: 'Out sick',
    key:'out_sick',
    icon: '/images/outSickStatusIcon.svg',
    presence:'offline'
  },
  {
    id:5,
    title: 'Working remotely',
    key:'working_remotely',
    icon: '/images/remoteStatusIcon.svg',
    presence:'busy'
  },
  {
    id:6,
    title: 'Vacationing',
    key:'Vacationing',
    icon: '/images/vacationStatusIcon.svg',
    presence:'offline'
  },
]


export const myBridgeData = [
  {
    heading: "If you want excuses, small talks, and gossip, stick to TikTok, Facebook, and LinkedIn.",
    description: "For meaningful connections, professional growth, and career advancement, join myBridge.me. No BS, just real opportunities."
  },
  {
    heading: "LinkedIn/myBridge – Concerned about managing multiple online profiles? Problem solved!",
    description: "myBridge’s extension keeps your LinkedIn and myBridge profiles updated by syncing information across both apps seamlessly."
  },
  {
    heading: "myBridge.me integrates networking with productivity tools for seamless collaboration, scheduling, and connections",
    description: "Perfect for remote work, project management, and building a strong professional network."
  },
  {
    heading: "myBridge combines social networking with productivity in one seamless platform.",
    description: "Grow your network, collaborate, and manage appointments—stay engaged, efficient, and connected without distractions. It’s productivity and networking redefined."
  },
  {
    heading: "Who says productivity and social networking can’t coexist?",
    description: "myBridge unites collaboration, networking, and productivity tools, enabling you to connect, manage projects, and stay focused—all in one streamlined platform."
  },
  {
    heading: "myBridge: Productivity and social networking—no compromises.",
    description: "Seamlessly connect, collaborate, and stay efficient with a platform that integrates professional networking and productivity tools, helping you manage time, projects, and connections all in one place."
  },
  {
    heading: "Professional Networking 2.0",
    description: "myBridge combines professional networking with productivity in one seamless platform. Grow your network, collaborate, and manage appointments—stay engaged, efficient, and connected without distractions. It’s networking and productivity redefined."
  },
  {
    heading: "Why myBridge.me is necessary",
    description: "LinkedIn isn't enough for today's professional networking. Combine the strengths of myBridge.me and LinkedIn for an unbeatable networking experience."
  },
  {
    heading: "myBridge.me: the all-in-one social media app for professionals",
    description: "Networking, file sharing, calendar scheduling, meetings, calls, and job search – everything you need in one place. Join now!"
  },
  {
    heading: "Attention small businesses, employers, and hiring managers!",
    description: "myBridge.me offers a promo: free job posts, candidate screening, and delivery of ideal candidates. Try myBridge.me now!"
  },
  {
    heading: "Targeting small businesses, employers, and hiring managers",
    description: "myBridge.me promo offers free job posts, and screens, and delivers ideal candidates. Let myBridge handle hiring and deliver top talent to you."
  },
  {
    heading: "Using LinkedIn for networking?",
    description: "Don’t miss myBridge.me, a complementary tool with features LinkedIn lacks: myDisk, myTeam, myCalendar. Perfect for serious professionals. Enhance your LinkedIn experience today!"
  },
  {
    heading: "Professional networking 2.0, myBridge.me is LinkedIn on steroids.",
    description: "Built just for you with features like myDisk, myTeam, and myCalendar that LinkedIn lacks. Join now!"
  },
  {
    heading: "myBridge.me – LinkedIn on steroids!",
    description: "Built just for you, myBridge.me offers powerful features like myDisk, myTeam, and myCalendar that LinkedIn lacks. Join now and take your professional network to the next level!"
  },
  {
    heading: "myBridge.me: The networking app to watch",
    description: "Rivaling LinkedIn with features like myDisk, myTeam, and myCalendar. Perfect for networking, file storage, career growth, and free job posts for small businesses."
  },
  {
    heading: "Attention Working Professionals!",
    description: "Explore myBridge.me, the social platform built for your career. Enjoy unique features like networking, file sharing, scheduling, meetings, calls, and job search—beyond LinkedIn."
  },
  {
    heading: "Attention Small Businesses, Employers, and Hiring Managers!",
    description: "Try myBridge.me, your all-in-one hiring solution. Enjoy free job posts and candidate screening, plus tools for networking, file sharing, and scheduling. Simplify recruitment and find top talent—join today!"
  },
  {
    heading: "myBridge.me: The Perfect Complement to LinkedIn.",
    description: "Boost your professional networking with myBridge.me's unique features. Store and access files securely with myDisk, collaborate easily with myTeam’s workspace tools, and manage events with myCalendar. It’s the all-in-one platform for career growth."
  },
  {
    heading: "myBridge.me—the perfect complement to LinkedIn, not a rival!",
    description: "myBridge.me enhances your experience with features like myDisk for secure file access, myTeam for seamless collaboration, and myCalendar for organized scheduling. Streamline your workflow and boost your career today!"
  },
  {
    heading: "The Networking App to Watch Out For!",
    description: "myBridge.me is transforming professional networking with tools like myDisk for secure file access, myTeam for seamless collaboration, and myCalendar for effortless scheduling. Complement your career growth with myBridge.me—your all-in-one networking solution."
  },
  {
    heading: "A Top Choice for Secure Data Storage!",
    description: "For unmatched security and convenience, myBridge.me’s myDisk ranks among the top 5 cloud storage solutions. It offers secure file sharing, business-ready storage, and easy access—ideal for all your data needs. Join myBridge.me for reliable storage today!"
  },
  {
    heading: "Your All-In-One Professional Social Media Platform!",
    description: "myBridge.me is the ultimate solution for career management. Connect with industry leaders, securely share documents with myDisk, organize with myCalendar, conduct virtual meetings, and find job opportunities—all in one seamless platform. Simplify your professional life today!"
  },
  {
    heading: "Attention Small Businesses, Employers, and Hiring Managers!",
    description: "Discover myBridge.me—the ultimate hiring tool! Take advantage of our promotion: Free job posts, plus candidate screening, networking, file sharing, scheduling, and calls. Simplify recruitment and find the perfect fit—sign up today!"
  },
  {
    heading: "myBridge.me: The Perfect Complement to LinkedIn",
    description: "In today’s professional world, LinkedIn isn’t enough—myBridge.me enhances it. With features like networking for deeper engagement, secure file sharing, scheduling, virtual meetings, and job searching, myBridge.me fills the gaps LinkedIn can’t, offering a comprehensive professional networking solution."
  },
  {
    heading: "Professionals on myBridge.me mean business!",
    description: "If you're not tired of excuses, small talk, and gossip, stick to TikTok, Facebook, or LinkedIn. But if you're serious about meaningful connections, professional growth, and career advancement, myBridge.me is the platform for you. Join today to connect with like-minded professionals focused on real opportunities and success."
  },
  {
    heading: "Leave the Small Talk and Gossip Behind",
    description: "Serious professionals choose myBridge.me for meaningful connections and growth. Skip Facebook and LinkedIn distractions. Join today to elevate your networking with tools built for success. Experience the difference with myBridge.me!"
  },
  {
    heading: "Employers and Hiring Managers!",
    description: "myBridge.me is your ultimate hiring solution. Post jobs for free and simplify your recruitment process with seamless tools for networking, file sharing, and scheduling."
  },
  {
    heading: "myEvent: Host Events and Webinars Effortlessly",
    description: "Introducing myEvent from myBridge.me! Plan and execute flawless events with seamless team collaboration, secure file sharing, virtual meetings, and instant messaging. Elevate your event planning today with myEvent!"
  },
  {
    heading: "myTeam: Organize as a Community or Team",
    description: "Powered by myBridge.me, myTeam is the ultimate collaboration tool. Enjoy seamless teamwork, secure file sharing with unlimited storage, effective virtual meetings, and instant communication. Simplify workflows, boost productivity, and enhance team efficiency with myTeam."
  },
  {
    heading: "myDisk - A Top Choice for Secure Data Storage",
    description: "Powered by myBridge.me, myDisk offers unparalleled secure cloud document storage, ranked among the top 5 disk websites. Enjoy effortless file sharing and management with guaranteed safety and accessibility. Trust myBridge.me and myDisk for reliable, efficient storage solutions."
  },
  {
    heading: "Not a rival, but a complement",
    description: "myBridge.me offers features LinkedIn lacks: myDisk, myTeam, and myCalendar. This is professional networking 2.0, combining social networking with productivity in one seamless platform."
  },
  {
    heading: "Enhance your LinkedIn experience today with myBridge.me!",
    description: "A complementary tool for serious professionals, offering features LinkedIn lacks: myDisk, myTeam, and myCalendar. Take your networking to the next level! Professional networking redefined."
  },
  {
    heading: "myEvent Powered by myBridge.me",
    description: "myEvent lets you host free events and webinars for any audience size. It integrates with popular teleconference platforms, providing seamless access to your preferred location for smooth and efficient event execution."
  },
  {
    heading: "myCalendar Powered by myBridge.me",
    description: "myCalendar integrates seamlessly with most calendars, allowing you to choose where to add events, check for conflicts, and avoid double booking. It also integrates with popular teleconference platforms, ensuring easy access to your preferred location for smooth, efficient meetings."
  },
  {
    heading: "myBridge.me's appointment scheduler is ranked in the top 5 and is completely free!",
    description: "It integrates with popular teleconference platforms, ensuring seamless access to your preferred location for each appointment. Let your network and customers easily schedule virtual and in-person appointments with you using myBridge.me’s scheduler."
  }
];
